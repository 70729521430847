<template>
  <div class="activity_create">
    <div class="activity_create_back" @click="handleBack">
      <el-button type="text">
        <i class="el-icon-arrow-left"></i>返回酒店列表</el-button
      >
    </div>
    <div class="activity_create_title">
      {{ this.$route.query.type == "create" ? "发布酒店" : "编辑酒店" }}
    </div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="demo-ruleForm"
      label-position="right"
      label-width="130px"
    >
      <div class="activity_create_bg">
        <h4>选择城市</h4>
        <el-form-item label="选择所在城市 " prop="citySelect">
          <el-select
            v-model="ruleForm.citySelect"
            filterable
            clearable
            placeholder="请选择发布酒店的城市"
            style="margin-left: 0; width: 98%"
            @change="handleChangeSelect($event)"
          >
            <el-option
              v-for="item in optionsCity"
              :key="item.id"
              :label="item.country + '-' + item.city"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择位置经纬度">
          <div style="display: flex">
            <el-button @click="handlePosition">选择位置经纬度</el-button>

            <el-dialog
              title="选择位置经纬度"
              :visible.sync="mapVisible"
              :fullscreen="false"
              id="mapDialog"
              :close-on-click-modal="false"
              :append-to-body="true"
            >
              <div style="margin-bottom: 10px">
                地区：<el-input
                  v-model="location2"
                  class="lineinput"
                  style="width: 200px"
                  size="mini"
                />
                关键词：<el-input
                  v-model="keyword"
                  class="lineinput"
                  style="width: 200px"
                  size="mini"
                />
              </div>
              <baidu-map
                class="map"
                :center="center"
                :zoom="zoom"
                @ready="handler"
                :scroll-wheel-zoom="true"
                @click="clickEvent"
              >
                <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
                <bm-geolocation
                  anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                  :showAddressBar="true"
                  :autoLocation="true"
                  @locationSuccess="getLoctionSuccess"
                ></bm-geolocation>
                <bm-view
                  :style="{
                    width: '100%',
                    height: '300px',
                    flex: 1,
                    marginTop: '0px',
                  }"
                ></bm-view>
                <bm-local-search
                  :keyword="keyword"
                  :auto-viewport="false"
                  :location="location2"
                  style="height: 150px; overflow-y: scroll; margin: 2px 0"
                ></bm-local-search>
              </baidu-map>
              <div class="demo-input-suffix" style="margin-top: 2vh">
                <el-link type="info">经度：</el-link
                ><el-input
                  class="lineinput"
                  style="width: 200px"
                  size="mini"
                  v-model.number="locData.longitude"
                  :disabled="true"
                ></el-input>
                <el-link type="info"> 纬度：</el-link
                ><el-input
                  class="lineinput"
                  style="width: 200px"
                  size="mini"
                  v-model.number="locData.latitude"
                  :disabled="true"
                ></el-input>
                <el-link type="info"> 位置：</el-link
                ><el-input
                  class="lineinput"
                  style="width: 200px"
                  size="mini"
                  v-model="locData.address"
                  :disabled="true"
                ></el-input>
              </div>
              <div
                slot="footer"
                class="dialog-footer"
                style="margin-top: -30px"
              >
                <el-button size="small" @click.native="mapVisible = false"
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  @click.native="findlocation"
                  >确定</el-button
                >
              </div>
            </el-dialog>

            <el-button @click="handlePositionInput" style="margin-left: 10px"
              >手动输入经纬度</el-button
            >

            <el-dialog
              title="输入经纬度"
              :visible.sync="mapInputVisible"
              :fullscreen="false"
              :close-on-click-modal="false"
              :append-to-body="true"
            >
              <el-input
                placeholder="请输入经度"
                v-model="input.lng"
                style="margin-bottom: 10px"
              ></el-input>
              <el-input placeholder="请输入纬度" v-model="input.lat"></el-input>

              <div slot="footer" class="dialog-footer">
                <el-button size="small" @click.native="mapInputVisible = false"
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  @click.native="findInputlocation"
                  >确定</el-button
                >
              </div>
            </el-dialog>
          </div>
          <div v-if="locData.longitude && locData.latitude">
            经度：{{ this.locData.longitude }} 纬度：{{ this.locData.latitude }}
          </div>
        </el-form-item>
      </div>

      <div class="activity_create_bg">
        <h4>基础信息</h4>
        <el-form-item label="酒店名称" prop="goodsName">
          <el-input
            v-model="ruleForm.goodsName"
            maxlength="60"
            show-word-limit
            placeholder="请输入酒店名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="酒店位置" prop="goodsLocationName">
          <el-input
            v-model="ruleForm.goodsLocationName"
            placeholder="请输入酒店位置"
          ></el-input>
        </el-form-item>
      </div>

      <div class="activity_create_bg" v-if="this.$route.query.type == 'create'">
        <h4>房间信息</h4>
        <el-table
          :data="tableData"
          :row-class-name="rowClassName"
          style="width: 100%"
          border
        >
          <!-- <el-table-column
            label="序号"
            align="center"
            prop="xh"
            width="50"
          ></el-table-column> -->
          <el-table-column
            label="图片"
            align="center"
            prop="images"
            width="200"
          >
            <template slot-scope="{ row, $index }">
              <div v-if="!showEdit[$index]">
                <div v-if="typeof tableData[row.xh - 1].images == 'object'">
                  <el-image
                    style="width: 100px; height: 100px"
                    :src="tableData[row.xh - 1].images[0]"
                    :preview-src-list="tableData[row.xh - 1].images"
                  >
                  </el-image>
                </div>

                <div
                  v-else-if="typeof tableData[row.xh - 1].images == 'string'"
                >
                  <el-image
                    style="width: 100px; height: 100px"
                    :src="tableData[row.xh - 1].images[0]"
                    :preview-src-list="[
                      tableData[row.xh - 1].images.split(','),
                    ]"
                  >
                  </el-image>
                </div>
              </div>

              <el-upload
                :action="UploadUrl()"
                ref="uploadimgTable"
                :limit="10"
                :auto-upload="false"
                :on-change="imageChangeTable"
                :show-file-list="true"
                :file-list="fileListTable"
                list-type="picture-card"
                :on-exceed="handleExceedTable"
                :before-remove="handleRemoveTable"
                :class="{ disabled: uploadDisabledTable }"
                v-if="showEdit[$index]"
              >
                <i class="el-icon-plus"></i>
              </el-upload> </template
          ></el-table-column>
          <el-table-column
            prop="goodsName"
            align="center"
            :required="true"
            label="名称"
            width="150"
          >
            <template slot-scope="{ row, $index }">
              <span v-if="!showEdit[$index]">{{ row.goodsName }}</span>
              <el-input
                v-model="tableData[row.xh - 1].goodsName"
                v-if="showEdit[$index]"
                placeholder="请输入名称"
              >
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="roomDesc"
            align="center"
            :required="true"
            label="房间描述"
            width="150"
          >
            <template slot-scope="{ row, $index }">
              <span v-if="!showEdit[$index]">{{ row.roomDesc }}</span>
              <el-input
                v-model="tableData[row.xh - 1].roomDesc"
                placeholder="请输入房间描述"
                v-if="showEdit[$index]"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="bedDesc"
            align="center"
            :required="true"
            label="床型描述"
            width="150"
          >
            <template slot-scope="{ row, $index }">
              <span v-if="!showEdit[$index]">{{ row.bedDesc }}</span>
              <el-input
                v-model="tableData[row.xh - 1].bedDesc"
                placeholder="请输入床型描述"
                v-if="showEdit[$index]"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="roomCapacity"
            align="center"
            :required="true"
            label="房间容量"
            width="150"
          >
            <template slot-scope="{ row, $index }">
              <span v-if="!showEdit[$index]">{{ row.roomCapacity || 0 }}</span>
              <el-input-number
                v-model="tableData[row.xh - 1].roomCapacity"
                v-if="showEdit[$index]"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column
            prop="cancelRule"
            align="center"
            label="退订政策"
            width="150"
          >
            <template slot-scope="{ row, $index }">
              <span v-if="!showEdit[$index]">{{
                row.cancelRule == 1
                  ? "提前1天退订"
                  : row.cancelRule == 2
                  ? "提前3天退订"
                  : row.cancelRule == 3
                  ? "提前7天退订"
                  : row.cancelRule == 4
                  ? "提前14天退订"
                  : row.cancelRule == 0
                  ? "不可退订"
                  : ""
              }}</span>
              <el-select
                v-model="tableData[row.xh - 1].cancelRule"
                v-if="showEdit[$index]"
                placeholder="请选择"
                value-key="value"
                @change="cancelRuleChange"
              >
                <el-option
                  v-for="item in cancelRuleArray"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            prop="hasBreakfast"
            align="center"
            :required="true"
            label="是否含早餐"
            width="100"
          >
            <template slot-scope="{ row, $index }">
              <span v-if="!showEdit[$index]">{{
                row.hasBreakfast ? "包含" : "不包含"
              }}</span>
              <el-checkbox
                v-model="tableData[row.xh - 1].hasBreakfast"
                v-if="showEdit[$index]"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="baseStock"
            align="center"
            :required="true"
            label="默认库存"
            width="150"
          >
            <template slot-scope="{ row, $index }">
              <span v-if="!showEdit[$index]">{{ row.baseStock || 0 }}</span>
              <el-input-number
                v-model="tableData[row.xh - 1].baseStock"
                v-if="showEdit[$index]"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column
            prop="basePrice"
            align="center"
            :required="true"
            label="默认价格"
            width="280"
          >
            <template slot-scope="{ row, $index }">
              <span v-if="!showEdit[$index]">{{ row.basePrice }}</span>
              <hr />
              <el-input-number
                v-model="tableData[row.xh - 1].basePrice"
                v-if="showEdit[$index]"
              ></el-input-number>
              <div class="create_table">
                <div>
                  <p>A级专属价</p>
                  <p>
                    {{ tableData[row.xh - 1].aDiscount || 10 }}折<i
                      class="el-icon-edit"
                      style="cursor: pointer"
                      @click="handleEditPrice('a')"
                      v-if="showEdit[$index]"
                    ></i>
                  </p>
                </div>
                <div>
                  <p>B级专属价</p>

                  <p>
                    {{ tableData[row.xh - 1].bDiscount || 10 }}折<i
                      class="el-icon-edit"
                      style="cursor: pointer"
                      @click="handleEditPrice('b')"
                      v-if="showEdit[$index]"
                    ></i>
                  </p>
                </div>
                <div>
                  <p>C级专属价</p>
                  <p>
                    {{ tableData[row.xh - 1].cDiscount || 10 }}折<i
                      class="el-icon-edit"
                      style="cursor: pointer"
                      @click="handleEditPrice('c')"
                      v-if="showEdit[$index]"
                    ></i>
                  </p>
                </div>
                <div>
                  <p>D级专属价</p>
                  <p>
                    {{ tableData[row.xh - 1].dDiscount || 10 }}折<i
                      class="el-icon-edit"
                      style="cursor: pointer"
                      @click="handleEditPrice('d')"
                      v-if="showEdit[$index]"
                    ></i>
                  </p>
                </div>

                <el-dialog
                  title="设置专属折扣"
                  :append-to-body="true"
                  :visible.sync="dialogFormVisible"
                >
                  <el-form :model="exclusivePrice">
                    <p style="margin: 0 0 10px 30px; color: #f56c6c">
                      ps：输入8.5 则为八五折，输入9 则为九折
                    </p>
                    <el-form-item
                      label="A级专属折扣"
                      :label-width="formLabelWidth"
                      v-if="exclusivePrice.priceType == 1"
                    >
                      <el-input-number
                        style="width: 300px"
                        v-model="tableData[row.xh - 1].aDiscount"
                        :min="0"
                        autocomplete="off"
                      ></el-input-number>
                    </el-form-item>
                    <el-form-item
                      label="B级专属折扣"
                      :label-width="formLabelWidth"
                      v-else-if="exclusivePrice.priceType == 2"
                    >
                      <el-input-number
                        style="width: 300px"
                        v-model="tableData[row.xh - 1].bDiscount"
                        :min="0"
                        autocomplete="off"
                      ></el-input-number>
                    </el-form-item>
                    <el-form-item
                      label="C级专属折扣"
                      :label-width="formLabelWidth"
                      v-else-if="exclusivePrice.priceType == 3"
                    >
                      <el-input-number
                        style="width: 300px"
                        v-model="tableData[row.xh - 1].cDiscount"
                        :min="0"
                        autocomplete="off"
                      ></el-input-number>
                    </el-form-item>
                    <el-form-item
                      label="D级专属折扣"
                      :label-width="formLabelWidth"
                      v-else-if="exclusivePrice.priceType == 4"
                    >
                      <el-input-number
                        style="width: 300px"
                        v-model="tableData[row.xh - 1].dDiscount"
                        :min="0"
                        autocomplete="off"
                      ></el-input-number>
                    </el-form-item>
                  </el-form>
                  <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false"
                      >取 消</el-button
                    >
                    <el-button type="primary" @click="dialogFormVisible = false"
                      >确 定</el-button
                    >
                  </div>
                </el-dialog>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            :required="true"
            label="指定日期价格"
            width="130"
          >
            <template slot-scope="{ row, $index }">
              <el-button
                type="text"
                @click="handleSetDatePrice"
                :disabled="!showEdit[$index]"
                >设置</el-button
              >
              <el-dialog
                title="指定日期价格"
                :visible.sync="dialogDatePriceVisible"
                :append-to-body="true"
              >
                <div>
                  <el-table
                    :data="tableDataPrice"
                    :row-class-name="rowClassNamePrice"
                    style="width: 100%"
                    border
                  >
                    <el-table-column
                      prop="mescode"
                      align="center"
                      :required="true"
                      label="日期"
                    >
                      <template slot-scope="{ row, $index }">
                        <div v-for="(item, i) in row.dateArr" :key="i">
                          <span v-if="!showEditPrice[$index]">{{ item }}</span>
                        </div>

                        <div>
                          <el-date-picker
                            type="dates"
                            v-model="tableDataPrice[row.xh - 1].dateArr"
                            value-format="yyyy-MM-dd"
                            placeholder="选择⼀个或多个⽇期"
                            v-if="showEditPrice[$index]"
                          ></el-date-picker>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="price"
                      align="center"
                      :required="true"
                      label="价格"
                    >
                      <template slot-scope="{ row, $index }">
                        <span v-if="!showEditPrice[$index]">{{
                          row.basePrice
                        }}</span>
                        <el-input-number
                          v-model="tableDataPrice[row.xh - 1].basePrice"
                          v-if="showEditPrice[$index]"
                        ></el-input-number>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="stock"
                      align="center"
                      :required="true"
                      label="库存"
                    >
                      <template slot-scope="{ row, $index }">
                        <span v-if="!showEditPrice[$index]">{{
                          row.stock
                        }}</span>
                        <el-input-number
                          v-model="tableDataPrice[row.xh - 1].stock"
                          v-if="showEditPrice[$index]"
                        ></el-input-number>
                      </template>
                    </el-table-column>

                    <el-table-column
                      header-align="center"
                      align="center"
                      width="100"
                      label="操作"
                    >
                      <template slot-scope="{ row, $index }">
                        <el-button
                          v-if="!showEditPrice[$index]"
                          @click="showUpdatePrice($index, row)"
                          type="text"
                          size="small"
                          >编辑</el-button
                        >
                        <el-button
                          v-if="!showEditPrice[$index]"
                          type="text"
                          size="small"
                          style="color: red"
                          >删除</el-button
                        >

                        <el-button
                          v-if="showEditPrice[$index]"
                          @click="submitKeepPrice($index, row)"
                          type="text"
                          size="small"
                          style="color: #85ce61"
                          >确定</el-button
                        >
                        <el-button
                          v-if="showEditPrice[$index]"
                          @click="cancelUpdatePrice($index)"
                          type="text"
                          size="small"
                          style="color: red"
                          >取消</el-button
                        >
                      </template>
                    </el-table-column>
                  </el-table>

                  <el-button
                    type="success"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAddBtnPrice"
                    >添加自选日期</el-button
                  >
                </div>

                <div
                  style="display: flex; align-items: center; margin-top: 10px"
                >
                  <p style="width: 120px">设置每周六价格</p>
                  <el-input-number
                    style="width: 30%"
                    v-model="everySatPrice"
                    placeholder="请输入每周六价格"
                    :min="0"
                  ></el-input-number>
                </div>
                <div
                  style="display: flex; align-items: center; margin-top: 10px"
                >
                  <p style="width: 120px">设置每周日价格</p>
                  <el-input-number
                    style="width: 30%"
                    v-model="everySunPrice"
                    placeholder="请输入每周六价格"
                    :min="0"
                  ></el-input-number>
                </div>

                <span slot="footer" class="dialog-footer">
                  <el-button @click="dialogDatePriceVisible = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="handleAddBtnPriceSubmit"
                    >确 定</el-button
                  >
                </span>
              </el-dialog>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            fixed="right"
            width="100"
            label="操作"
          >
            <template slot-scope="{ row, $index }">
              <el-button
                v-if="!showEdit[$index]"
                @click="showUpdate($index, row)"
                type="text"
                size="small"
                >编辑</el-button
              >
              <el-button
                v-if="!showEdit[$index]"
                type="text"
                size="mini"
                style="color: #f56c6c"
                @click="handleDeleteBtn($index, row)"
                >删除</el-button
              >
              <el-button
                v-if="showEdit[$index]"
                @click="submitKeep($index, row)"
                type="text"
                size="small"
                style="color: #85ce61"
                >确定</el-button
              >
              <el-button
                v-if="showEdit[$index]"
                @click="cancelUpdate($index)"
                type="text"
                size="small"
                style="color: red"
                >取消</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <el-button
          type="success"
          icon="el-icon-plus"
          size="mini"
          @click="handleAddBtn"
          >添加</el-button
        >
      </div>

      <div class="activity_create_bg">
        <h4>图文描述</h4>

        <el-form-item label="主图图片 " prop="img">
          <p style="color: #000000; opacity: 0.5; margin-left: 0">
            上传1:1的图片,大小不能超过10MB（默认第一张图片为封面主图）
          </p>
          <div class="video_upload">
            <el-upload
              :action="UploadUrl()"
              ref="uploadimg"
              :auto-upload="false"
              :on-change="imageChange"
              :show-file-list="true"
              :file-list="[]"
              list-type="picture-card"
              :class="{ disabled: uploadDisabled }"
            >
              <i class="el-icon-plus"></i>
            </el-upload>

            <el-progress
              v-show="progressFlagImg"
              :percentage="loadProgressImg"
            ></el-progress>

            <!-- 拖拽组件 -->
            <draggable
              v-model="zlist"
              :animation="100"
              class="dira"
              @update="handleDraggable"
            >
              <div class="isblocks" v-for="(item, index) in zlist" :key="index">
                <div class="ress">
                  <el-image
                    ref="preview"
                    :style="{
                      width: upWith + 'px',
                      height: upHeight + 'px',
                      borderRadius: upBradius + 'px',
                    }"
                    :preview-src-list="zlist"
                    :src="item"
                  ></el-image>
                  <div class="imgs_prews">
                    <div
                      class="imgs_prew"
                      :style="{
                        width: upWith + 'px',
                        height: upHeight + 'px',
                        borderRadius: upBradius + 'px',
                      }"
                    >
                      <i @click="ylimg(index)" class="el-icon-view"></i>
                      <span></span>
                      <i @click="deleteimg(index)" class="el-icon-delete"></i>
                    </div>
                  </div>
                </div>
              </div>
            </draggable>
          </div>
        </el-form-item>

        <el-form-item label="上传视频 " prop="video">
          <p style="color: #000000; opacity: 0.5; margin-left: 0">
            大小不能超过 600MB
          </p>

          <div class="video_upload">
            <el-upload
              ref="video"
              list-type="picture-card"
              v-model="ruleForm.videoPath"
              :action="UploadUrl()"
              :show-file-list="false"
              :on-change="imageChangeVideo"
              :on-progress="uploadVideoProcess"
              :http-request="UploadVideo"
            >
              <i class="el-icon-upload"></i>
            </el-upload>

            <div class="list-vedio">
              <div v-for="(item, index) in fileListVideo" :key="index">
                <video
                  v-if="ruleForm.videoPath != '' && videoFlag == false"
                  :src="item"
                  class="avatar"
                  controls="controls"
                >
                  您的浏览器不支持视频播放
                </video>
                <el-button type="danger" @click="handleRemoveVideo(index)"
                  >删除该视频</el-button
                >
              </div>
            </div>

            <el-progress
              v-show="progressFlag"
              :percentage="loadProgress"
            ></el-progress>
          </div>
        </el-form-item>

        <el-form-item
          label="详情描述"
          prop="hotelLinkEdit"
          class="rich_z-index"
        >
          <div id="richText"></div>

          <div class="el-form_btn_b_save">
            <el-button @click="handleSaveShelves('zh')">保存草稿</el-button>
            <div class="el-form_btn_b_save_div" v-if="isSave">
              <el-button
                type="success"
                icon="el-icon-check"
                size="mini"
                circle
              ></el-button
              >最后保存于{{ getTimeSave }}
            </div>
          </div>
        </el-form-item>
      </div>

      <div class="activity_create_bg" v-if="this.$route.query.type == 'create'">
        <h4>是否上架</h4>

        <el-form-item label="是否上架" prop="isVisible">
          <el-radio-group v-model="ruleForm.isVisible">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <div class="el_form_item_footer">
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          >

          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import axios from "axios";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";

import Breadcrumb from "@/components/BreadCrumb";
import E from "wangeditor"; //引入富文本插件

import draggable from "vuedraggable";

let editor;

export default {
  props: {
    //宽度
    upWith: {
      type: Number,
      default: 100,
    },
    //高度
    upHeight: {
      type: Number,
      default: 100,
    },
    //圆角
    upBradius: {
      type: Number,
      default: 0,
    },
  },
  inject: ["reload"],
  components: {
    Breadcrumb,
    draggable,
  },

  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行世界",
        },
        {
          name: "活动管理",
        },
        {
          name: "发布活动",
        },
      ], // 面包屑数据

      ruleForm: {
        citySelect: "",
        goodsName: "",
        goodsLocationName: "",
        isVisible: false,
        videoPath: "",
      },

      location2: "",
      keyword: "",
      center: { lng: 111.752912, lat: 40.832246 },
      zoom: 12,
      mapVisible: false,
      locData: {
        longitude: "",
        latitude: "",
        address: "",
      },
      temp: {
        location: "",
        lng: "",
        lat: "",
      },

      mapInputVisible: false,
      input: {
        lng: "",
        lat: "",
      },

      optionsCity: [],

      rules: {
        citySelect: [
          { required: true, message: "请选择发布的酒店城市", trigger: "blur" },
        ],
        goodsName: [
          { required: true, message: "请输入酒店名称", trigger: "blur" },
          { min: 0, max: 60, message: "已超出字数限制", trigger: "blur" },
        ],
        goodsLocationName: [
          { required: true, message: "请输入酒店位置", trigger: "blur" },
        ],
      },
      fileList: [],
      fileListImg: [],
      uploadDisabled: false,
      progressFlag: false,
      loadProgress: 0,
      zlist: [], //组件数组

      fileListVideo: [],
      videoFlag: false,
      // //是否显示进度条
      videoUploadPercent: 0,

      worldHotelDetailRow: {},

      isSave: false,
      getTimeSave: "",

      tableData: [],
      cancelRuleArray: [
        {
          value: 0,
          label: "不可退订",
        },
        {
          value: 1,
          label: "提前1天退订",
        },
        {
          value: 2,
          label: "提前3天退订",
        },
        {
          value: 3,
          label: "提前7天退订",
        },
        {
          value: 4,
          label: "提前14天退订",
        },
      ],

      showEdit: [], //控制显示及隐藏
      getCancelRule: [],

      exclusivePrice: {
        priceType: 1,
      },

      dialogFormVisible: false,
      formLabelWidth: "120px",
      dialogDatePriceVisible: false,

      // 指定日期价格
      dateArr: "",
      everySatPrice: "",
      everySunPrice: "",
      tableDataPrice: [],
      tableDataPriceList: [],
      showEditPrice: [], //控制显示及隐藏
      fileListTable: [],
      fileListImgTable: [],
      uploadDisabledTable: false,
      fileImg: "",
      tableIndex: 0,

      progressFlagImg: false,
      loadProgressImg: 0,
      deleteList: [],
      dataCopyInfo: {},
      dataInxInfo: 0,
    };
  },

  watch: {
    loadProgress(newValue) {
      if (newValue == 100) {
        this.progressFlag = false;
      }
    },

    fileListImg(newvalue) {
      this.fileListImg = newvalue;
    },
  },

  created() {
    //回显数组
    this.zlist = this.fileListImg;
  },

  mounted() {
    setTimeout(() => {
      // 中文
      editor = new E("#richText");
      editor.config.uploadImgServer = reqUrl.richImageUpload;
      editor.config.uploadImgMaxSize = 20 * 1024 * 1024;
      editor.config.uploadFileName = "file";
      editor.create();
      if (this.$route.query.type == "edit") {
        editor.txt.html(this.worldHotelEdit.detailLinkEdit);
      } else {
        editor.txt.html();
      }
    }, 100);

    const opt = {
      url: reqUrl.worldTravelSelectCity,
      method: "POST",
      params: JSON.stringify({
        city: "",
        country: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        } else if (res.data.code == 0) {
          this.optionsCity = res.data.data.list;
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
      },
    };
    request(opt);

    if (this.$route.query.type == "edit") {
      this.worldHotelEdit = JSON.parse(
        localStorage.getItem("worldHotelDetailRow")
      );

      this.ruleForm = this.worldHotelEdit;
      this.ruleForm.citySelect = this.worldHotelEdit.cityId;
      this.locData.longitude = this.worldHotelEdit.goodsLocationLng;
      this.locData.latitude = this.worldHotelEdit.goodsLocationLat;
      let images = this.worldHotelEdit.images.split(",");
      console.log(images, "images");
      this.zlist = images;
      let videos = this.worldHotelEdit.videos.split(",");
      this.fileListVideo = videos;
      for (var i = 0; i < images.length; i++) {
        var str = images[i];
        this.fileList.push({ url: str });
      }
    }
  },

  methods: {
    handleBack() {
      this.$router.push("/worldHotelList");
    },

    UploadUrl() {
      return reqUrl.imageUpload;
    },

    //选择图片后做图片格式限制（手动上传图片时，before-upload钩子无效，使用此方法替代）
    imageChange(file, fileList) {
      this.progressFlagImg = true;
      this.loadProgressImg = 0;
      const interval = setInterval(() => {
        if (this.loadProgressImg >= 99) {
          clearInterval(interval);
          return;
        }
        this.loadProgressImg += 1;
      }, 20);

      axios.get(file.url, { responseType: "blob" }).then((res) => {
        const formData = new FormData();
        formData.append("file", res.data);
        formData.append("userId", 1);
        formData.append("scene", 2);

        axios.defaults.headers.post["Authorization"] = localStorage.getItem(
          "resHeadersAuthorization"
        );
        axios({
          method: "POST",
          url: reqUrl.imageUpload,
          header: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        }).then((res) => {
          // this.fileListImg.push(res.data.data.url);
          this.zlist.push(res.data.data.url);

          this.progressFlagImg = false;
          this.loadProgress = 100;
        });
      });

      const isImage =
        file.raw.type == "image/png" ||
        file.raw.type == "image/jpg" ||
        file.raw.type == "image/jpeg";
      const isLt5M = file.size < 1024 * 1024 * 10;
      if (!isImage) {
        this.$message.error("上传只能是png,jpg,jpeg格式!");
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }

      if (isImage && isLt5M) {
        this.uploadFile = file.raw || null;
      } else {
        fileList.splice(-1, 1);
      }
    },

    handleDraggable(event) {
      const { oldIndex, newIndex } = event;

      this.fileListImg.splice(
        newIndex,
        0,
        this.fileListImg.splice(oldIndex, 1)[0]
      );
    },

    //删除
    deleteimg(index) {
      this.$delete(this.zlist, index);
    },
    //预览
    ylimg(index) {
      this.$refs.preview[index].showViewer = true;
    },

    //视频上传
    UploadVideo(params) {
      this.ruleForm.videoPath = URL.createObjectURL(params.file);
      this.progressFlag = true;
      this.loadProgress = 0;
      const interval = setInterval(() => {
        if (this.loadProgress >= 99) {
          clearInterval(interval);
          return;
        }
        this.loadProgress += 1;
      }, 20);
    },

    imageChangeVideo(file, fileList) {
      axios.get(file.url, { responseType: "blob" }).then((res) => {
        const formData = new FormData();
        formData.append("file", res.data);
        formData.append("userId", 1);
        formData.append("scene", 2);

        axios.defaults.headers.post["Authorization"] = localStorage.getItem(
          "resHeadersAuthorization"
        );
        axios({
          method: "POST",
          url: reqUrl.imageUpload,
          header: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        }).then((res) => {
          this.fileListVideo.push(res.data.data.url);
          this.loadProgress = 100;
        });
      });

      const isLt5M = file.size < 1024 * 1024 * 600;

      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 600MB!");
      }

      if (isLt5M) {
        this.uploadFile = file.raw || null;
      } else {
        fileList.splice(-1, 1);
      }
    },

    // 删除视频
    handleRemoveVideo(index) {
      this.fileListVideo.splice(index, 1);
    },

    uploadVideoProcess(event, file, fileList) {
      this.videoFlag = true;
      this.videoUploadPercent = file.percentage.toFixed(0) * 1;
    },

    handleSaveShelves(type) {
      if (type == "zh") {
        const opt = {
          url: reqUrl.goodsStringToFileUploadUrl,
          method: "POST",
          params: JSON.stringify({
            fileString: editor.txt.html(),
            title: this.ruleForm.goodsName,
          }),
          resFunc: (res) => {
            console.log(res.data);
            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
            } else if (res.data.code == 0) {
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.isSave = true;
              this.getTimeSave = new Date().toLocaleString();

              localStorage.setItem("htmlUrlHotelZh", res.data.data.url);
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
          },
        };
        request(opt);
      }
    },

    handleChangeSelect(e) {
      this.$set(this.ruleForm, "citySelect", e);
      this.$forceUpdate();
    },

    handlePosition() {
      this.mapVisible = true;
    },

    handlePositionInput() {
      this.mapInputVisible = true;
    },

    handler({ BMap, map }) {
      let _this = this; // 设置一个临时变量指向vue实例，因为在百度地图回调里使用this，指向的不是vue实例；
      let geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(
        function (r) {
          console.log(r);
          _this.center = { lng: r.longitude, lat: r.latitude }; // 设置center属性值
          _this.autoLocationPoint = { lng: r.longitude, lat: r.latitude }; // 自定义覆盖物
          _this.initLocation = true;
        },
        { enableHighAccuracy: true }
      );
      window.map = map;
    },

    //点击地图监听
    clickEvent(e) {
      map.clearOverlays();
      let Icon_0 = new BMap.Icon(
        "http://api0.map.bdimg.com/images/marker_red_sprite.png",
        new BMap.Size(64, 64),
        { anchor: new BMap.Size(18, 32), imageSize: new BMap.Size(36, 25) }
      );
      let myMarker = new BMap.Marker(new BMap.Point(e.point.lng, e.point.lat), {
        icon: Icon_0,
      });
      map.addOverlay(myMarker);
      //用所定位的经纬度查找所在地省市街道等信息
      let point = new BMap.Point(e.point.lng, e.point.lat);
      let gc = new BMap.Geocoder();
      let _this = this;
      gc.getLocation(point, function (rs) {
        let addComp = rs.addressComponents;
        _this.locData.address = rs.address;
      });
      this.locData.longitude = e.point.lng;
      this.locData.latitude = e.point.lat;
    },

    //定位成功回调
    getLoctionSuccess(point, AddressComponent, marker) {
      map.clearOverlays();
      let Icon_0 = new BMap.Icon(
        "http://api0.map.bdimg.com/images/marker_red_sprite.png",
        new BMap.Size(64, 64),
        { anchor: new BMap.Size(18, 32), imageSize: new BMap.Size(36, 36) }
      );
      let myMarker = new BMap.Marker(
        new BMap.Point(point.point.lng, point.point.lat),
        { icon: Icon_0 }
      );
      map.addOverlay(myMarker);
      this.locData.longitude = point.point.lng;
      this.locData.latitude = point.point.lat;
    },

    //返回选中点的位置
    findlocation() {
      this.temp.location = this.keyword;
      this.temp.lng = this.locData.longitude;
      this.temp.lat = this.locData.latitude;
      this.mapVisible = false;
    },

    // 手动输入-返回经纬度位置
    findInputlocation() {
      this.locData.longitude = this.input.lng;
      this.locData.latitude = this.input.lat;
      this.mapInputVisible = false;
    },

    //表格的新增
    rowClassName({ row, rowIndex }) {
      row.xh = rowIndex + 1;
    },

    //点击新增更多
    handleAddBtn() {
      this.getCancelRule = "";
      let obj = {};
      obj.images = [];
      obj.goodsName = "";
      obj.roomDesc = "";
      obj.bedDesc = "";
      obj.roomCapacity = 0;
      obj.baseStock = 0;
      obj.cancelRule = "";
      obj.hasBreakfast = true;
      obj.basePrice = 0;
      obj.aDiscount = 10;
      obj.bDiscount = 10;
      obj.cDiscount = 10;
      obj.dDiscount = 10;

      this.tableData.push(obj);
      this.uploadDisabledTable = false;
    },

    //删除
    handleDeleteBtn(index, val) {
      this.$confirm("请确认是否要删除该项", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.tableData.forEach((v, i) => {
          if (val.xh === v.xh) {
            this.tableData.splice(i, 1);
          }
        });
      });
    },

    //点击修改
    showUpdate(index, row) {
      this.showEdit[index] = true;
      this.$set(this.showEdit, index, true); //这里要用$set方法，否则页面状态不更新

      if (row.images.length > 0) {
        let arr = [];
        row.images.map((item) => {
          arr.push({ url: item });
        });
        this.fileListTable = arr;
      }
    },

    //取消修改
    cancelUpdate(index) {
      this.$set(this.showEdit, index, false);
    },

    //提交修改
    submitKeep(index, row) {
      //发送请求，隐藏输入框
      this.$message({
        type: "success",
        message: "已缓存，记得点击保存提交修改喔！",
        duration: 888,
        onClose: () => {
          this.$set(this.showEdit, index, false); //vue添加属性的方法
        },
      });

      setTimeout(() => {
        row.images = this.fileListImgTable;

        this.tableData[index].roomSkuList = JSON.parse(
          localStorage.getItem("tableDataPriceList")
        );

        this.tableIndex = index;
      }, 1000);
    },

    // 退订政策下拉框
    cancelRuleChange(e) {
      this.getCancelRule = e;
    },

    // 设置专属价格
    handleEditPrice(type) {
      this.dialogFormVisible = true;
      if (type == "a") {
        this.exclusivePrice.priceType = 1;
      } else if (type == "b") {
        this.exclusivePrice.priceType = 2;
      } else if (type == "c") {
        this.exclusivePrice.priceType = 3;
      } else if (type == "d") {
        this.exclusivePrice.priceType = 4;
      }
    },

    // 指定日期价格
    handleSetDatePrice() {
      this.dialogDatePriceVisible = true;
    },
    rowClassNamePrice({ row, rowIndex }) {
      row.xh = rowIndex + 1;
    },
    handleAddBtnPrice() {
      let obj = {};
      obj.dateArr = "";
      obj.basePrice = 0;
      obj.stock = 0;

      this.tableDataPrice.push(obj);
    },
    showUpdatePrice(index, row) {
      this.showEditPrice[index] = true;
      this.$set(this.showEditPrice, index, true); //这里要用$set方法，否则页面状态不更新
    },
    cancelUpdatePrice(index) {
      this.$set(this.showEditPrice, index, false);
    },
    submitKeepPrice(index, row) {
      //发送请求，隐藏输入框
      this.$message({
        type: "success",
        message: "已缓存，记得点击保存提交修改喔！",
        duration: 888,
        onClose: () => {
          this.$set(this.showEditPrice, index, false); //vue添加属性的方法
        },
      });
    },
    handleAddBtnPriceSubmit() {
      this.dialogDatePriceVisible = false;

      this.tableDataPrice.map((item) => {
        if (item.dateArr) {
          this.tableDataPriceList = item.dateArr.map((date) => ({
            roomDate: date,
            basePrice: item.basePrice.toString(),
            stock: item.stock,
          }));
        }
      });

      localStorage.setItem(
        "tableDataPriceList",
        JSON.stringify(this.tableDataPriceList)
      );

      // 为每个日期创建一个新对象
    },
    //选择图片后做图片格式限制（手动上传图片时，before-upload钩子无效，使用此方法替代）
    imageChangeTable(file, fileList) {
      if (fileList.length >= 10) {
        this.uploadDisabledTable = true;
      }
      axios.get(file.url, { responseType: "blob" }).then((res) => {
        const formData = new FormData();
        formData.append("file", res.data);
        formData.append("userId", 1);
        formData.append("scene", 2);

        axios.defaults.headers.post["Authorization"] = localStorage.getItem(
          "resHeadersAuthorization"
        );
        axios({
          method: "POST",
          url: reqUrl.imageUpload,
          header: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        }).then((res) => {
          this.fileListImgTable.push(res.data.data.url);
          this.fileImg = res.data.data.url;
        });
      });

      const isImage =
        file.raw.type == "image/png" ||
        file.raw.type == "image/jpg" ||
        file.raw.type == "image/jpeg";
      const isLt5M = file.size < 1024 * 1024 * 5;
      if (!isImage) {
        this.$message.error("上传只能是png,jpg,jpeg格式!");
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 600MB!");
      }

      if (isImage && isLt5M) {
        this.uploadFile = file.raw || null;
      } else {
        fileList.splice(-1, 1);
      }
    },
    //超出限制个数钩子
    handleExceedTable(files) {
      this.$message.warning("当前限制选择10个文件，请删除图片后重新添加");
    },

    // 删除图片
    handleRemoveTable(file, fileList) {
      const index = fileList.findIndex((item) => {
        return item.uid === file.uid;
      });
      this.fileListImgTable.splice(index, 1);
      // this.fileListTable.splice(index, 1);

      if (this.fileListImgTable.length < 1) {
        this.uploadDisabledTable = false;
      }
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.$route.query.type == "create") {
            if (this.locData.longitude !== "" && this.locData.latitude !== "") {
              this.tableData.forEach((item) => {
                const { aDiscount, bDiscount, cDiscount, dDiscount } = item;
                item.aDiscount = parseInt((aDiscount / 10) * 100) / 100;
                item.bDiscount = parseInt((bDiscount / 10) * 100) / 100;
                item.cDiscount = parseInt((cDiscount / 10) * 100) / 100;
                item.dDiscount = parseInt((dDiscount / 10) * 100) / 100;
              });

              this.tableData[this.tableIndex].images =
                this.tableData[this.tableIndex].images.join(",");

              showLoading();
              const opt = {
                url: reqUrl.worldHotelAddHotel,
                method: "POST",
                params: JSON.stringify({
                  cityId: this.ruleForm.citySelect, // 城市id
                  goodsName: this.ruleForm.goodsName, // 酒店名称
                  goodsLocationName: this.ruleForm.goodsLocationName, // 酒店地址
                  goodsLocationLng: this.locData.longitude, // 经度
                  goodsLocationLat: this.locData.latitude, // 纬度
                  images: this.zlist.join(","), // 图片
                  videos: this.fileListVideo.join(","), // 视频
                  detailLink: localStorage.getItem("htmlUrlHotelZh"),
                  detailLinkEdit: editor.txt.html(),
                  rooms: this.tableData,
                  isVisible: this.ruleForm.isVisible, // 是否上架
                  everySatPrice: this.everySatPrice,
                  everySunPrice: this.everySunPrice,
                }),
                resFunc: (res) => {
                  console.log(res.data);
                  if (res.data.code != 0) {
                    this.$notify.error({
                      title: "错误",
                      message: res.data.message,
                    });
                    hideLoading();
                  } else if (res.data.code == 0) {
                    this.$message({
                      message: "酒店发布成功",
                      type: "success",
                    });
                    this.$router.push("/worldHotelList");
                    hideLoading();
                  }
                  hideLoading();
                },
                errFunc: (err) => {
                  this.$notify.error({
                    title: "错误",
                    message: err,
                  });
                  hideLoading();
                  console.log(err, "err1");
                },
              };
              request(opt);
            } else {
              this.$message.error("请检查是否填写经纬度");
            }
          } else if (this.$route.query.type == "edit") {
            showLoading();
            const opt = {
              url: reqUrl.worldHotelEditHotel,
              method: "POST",
              params: JSON.stringify({
                id: this.worldHotelEdit.id,
                cityId: this.ruleForm.citySelect, // 城市id
                goodsName: this.ruleForm.goodsName, // 酒店名称
                goodsLocationName: this.ruleForm.goodsLocationName, // 酒店地址
                goodsLocationLng: this.locData.longitude, // 经度
                goodsLocationLat: this.locData.latitude, // 纬度
                images: this.zlist.join(","), // 图片
                videos: this.fileListVideo.join(","), // 视频
                detailLink: localStorage.getItem("htmlUrlHotelZh"),
                detailLinkEdit: editor.txt.html(),
              }),
              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                  hideLoading();
                } else if (res.data.code == 0) {
                  this.$message({
                    message: "酒店编辑成功",
                    type: "success",
                  });
                  this.$router.push("/worldHotelList");
                  hideLoading();
                }
                hideLoading();
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                hideLoading();
                console.log(err, "err1");
              },
            };
            request(opt);
          }
        } else {
          this.$message.error("请检查填写信息是否有误");
          return false;
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },

  destroyed() {
    localStorage.removeItem("htmlUrlHotelZh");
    localStorage.removeItem("htmlUrllHotelUs");

    if (this.$route.query.type == "create") {
      localStorage.removeItem("tableDataPriceList");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";

.el_form_item_footer {
  display: flex;
  justify-content: center;
  ::v-deep .el-form-item__content {
    margin-left: 0 !important;
  }
}

.el-input-number {
  width: 100%;
}
</style>

<style>
.el-popper,
.el-message,
.el-notification {
  z-index: 1111111 !important;
}
</style>
